import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const ConditionalWrapper = ({ condition, wrapper, children }) => 
condition ? wrapper(children) : children;

const DownloadSection = (props) => {
  // swap for static image on preview
  const imageSelector =  props.coverImage
    ? <Img fixed={props.coverImage} alt='Free Book' />
    : <img 
        src={props.previewImage} 
        style={{
          width: 200,
          height: 300,
        }}
        alt='Free Book' />
  const secondImageSelector =  props.coverImage
    ? <Img fixed={props.secondImage} alt='Free Book' />
    : <img 
        src={props.previewSecondImage} 
        style={{
          width: 200,
          height: 300,
        }}
        alt='Free Book' />
  return(
    <ConditionalWrapper 
    condition={props.backgroundImage}
    wrapper={children => <BackgroundImage fluid={props.backgroundImage}>{children}</BackgroundImage>}>
      <div className={`container section`}>
        <div className="row" style={{justifyContent:"center"}}>
          <div className="col-lg-6 centerMob">
            <div className='row'>
              <div className='col-sm-6 d-flex' style={{justifyContent:"center"}}>
                {imageSelector}
              </div>
              <div className='col-sm-6 d-flex' style={{justifyContent:"center"}}>
                {secondImageSelector}
              </div>
            </div>
          </div>
          <div className={`col-lg-6 bookDetails text-center`}>   
            <h2>Get Free Stories</h2>
            <p>Free novellas introducing art detective John Kite</p>
            <p>Only available through this website</p>
            <div className="centerMob">
              <Link to='/download'><button className="btn btn-primary">Join the readers club</button></Link>
            </div>
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  )
}

export default DownloadSection